import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import Modal from "react-modal";

const ProjectSection = ({ data, animation, num, lastLength }) => {
  const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: {
          relativePath: {
            regex: "/^(igaming|sangti|kantime-lms|rentcity|fsl|trackier|elastic-sign|dms|dms-2|pay-invoice|pay-invoice-2|nirvana-casestudy|icegram|visa-work|visa-work-2|auto-service-web|auto-service-web-2|pro-employee|help-manual|nirvana|forex|shoppers-stop|sunday|hala-gig|board-meeting|ship-delight-web|distribution-management|align-plus|like-hire|survey|vet-it|kyss|landscape|gizmo|user-guides|visa|fio|stride|moms-belief|kantime-project|uni-logics|auto-service|auto-recruits|energy-trade|landscape-pro|kys|baa|captain-biz|kesari|alpha-test|developly|hushly-project|cloudforce)/"
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });
  const imagePaths = data.images.map((image) => {
    const imageNode = imageData.dynamicImages.edges.find(
      ({ node }) => node.relativePath === image
    );
    if (imageNode) {
      return imageNode.node.relativePath;
    }
    return null;
  });

  // EMPTY
  const [divIds, setDivIds] = useState([]);
  useEffect(() => {
    lastLength.forEach((div) => {
      const divElement = document.getElementById(`imageId${num}1`);
      if (divElement && divElement.innerHTML.trim() === "") {
        setDivIds((prevDivIds) => [...prevDivIds, `imageId${num}1`]);
      } else {
      }
    });
  }, [lastLength, num]);
  const [divIdsFirst, setDivIdsFirst] = useState([]);
  useEffect(() => {
    lastLength.forEach((div) => {
      const divElement = document.getElementById(`imageId${num}0`);
      if (divElement && divElement.innerHTML.trim() === "") {
        setDivIdsFirst((prevDivIds) => [...prevDivIds, `imageId${num}0`]);
      } else {
      }
    });
  }, [lastLength, num]);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const isMobileQuery = window.matchMedia("(max-width: 767px)");
      setIsMobile(isMobileQuery.matches);
      const isTabletQuery = window.matchMedia("(max-width: 1023px)");
      setIsTablet(isTabletQuery.matches)
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // modal
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  return (
    <div
      className={`mb-[100px] lg:mb-[200px] md:mb-[120px] ${
        animation ? "second-comesup" : ""
      }`}
    >
      <div className="container">
        <p className="lg:mb-[15px] md:mb-[20px] mb-[10px] font-heading font-semibold lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
          {data.title}
        </p>
        <p className="md:text-[20px] light lg:text-[30px] text-[18px] leading-[1.39] font-body lg:mb-13 mb-[50px]">
          {data.desc}
        </p>
        <div className="flex mb-2.5">
          <p className="lg:min-w-[250px] min-w-[37.33vw] md:min-w-[150px] lg:mr-10 mr-[25px] md:mr-[60px] font-body lg:text-[14px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] md:tracking-[.03em] tracking-[.02em] lg:leading-[22px] text-light-white lg:tracking-[.01em] font-medium uppercase ">
            Industry
          </p>
          <p className="font-body lg:text-[14px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] lg:leading-[22px] text-light-white md:tracking-[.03em] tracking-[.02em] lg:tracking-[.01em] font-medium uppercase">
            What we did
          </p>
        </div>
        <div className="flex mb-[43px] lg:mb-11">
          <p
            className={`lg:min-w-[250px] min-w-[37.33vw] md:min-w-[150px] lg:mr-10 mr-[25px] md:mr-[60px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] md:tracking-[.03em] tracking-[.02em] font-body lg:tracking-[.01em] lg:text-[14px] lg:leading-[24px] font-light ${"no-underline"}`}
          >
            {data.Industry}
          </p>
          <div className="flex flex-col md:flex-row gap-[10px] md:gap-[30px]">
            {data.WhatWeDid.map((task, index) => (
              <a
                target="_blank"
                rel="noreferrer"
                title={`${task.title} services`}
                href={task.link}
                key={index}
                className={`font-body md:text-[16px] text-[15px] w-fit leading-[26px] md:leading-[30px] md:tracking-[.03em] tracking-[.02em] lg:tracking-[.01em] lg:text-[14px] lg:leading-[24px] font-light cursor-pointer underline-link relative`}
              >
                {task.mobileTitle && isMobile ? task.mobileTitle : task.title}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="project-div">
        <Swiper
          className="project-slider view-slider"
          breakpoints={{
            100: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 'auto',
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 'auto',
              spaceBetween: 50,
            },
          }}
        >
          {imagePaths.map((imagePath, index) => {
            return (
              <SwiperSlide
                key={index}
                className="!flex flex-col justify-between"
              >
                <Link
                  to={data.link ? data.link : "/"}
                  className="link link-project-slide"
                  id={`imageId ${num} ${index}`}
                >
                  <GatsbyImage
                    image={imagesByPath[imagePath]}
                    alt={
                      data.firstAlt ? index === 0 ? data.firstAlt : index === 1 ? data.secondAlt : data.thirdAlt : data.title ? data.title : "project-section"
                    }
                    title={ data.title ? index === 0 ? data.firstTitle : index === 1 ? data.secondTitle : data.thirdTitle : data.title  }
                  />
                </Link>
                {divIdsFirst.includes(`imageId${num}0`) && index === 0 ? (
                  <StaticImage
                    src="../images/not-found-image.webp"
                    alt="image not found"
                  />
                ) : (
                  ""
                )}
                {divIds.includes(`imageId${num}1`) && index === 1 ? (
                  <StaticImage
                    src="../images/not-found-image.webp"
                    alt="image not found"
                  />
                ) : (
                  ""
                )}
              </SwiperSlide>
            );
          })}
          {
            data.thirdSlide !== undefined && 
            <SwiperSlide
              className="!flex flex-col justify-between"
            >
              {
                !isTablet ?
                <div className="link-project-slide text h-full mobile-popup">
                  <div className="p-[20px] md:p-[40px] h-full lg:p-[60px] bg-[#1F1F1F] flex-col flex gap-[30px] md:gap-[40px] lg:gap-[60px]">
                    <div>
                      <p className="mb-[16px] text-[22px] leading-[1.5] md:text-[28px] md:leading-[1.4] tracking-[.03em] font-semibold font-heading">{data.thirdSlide.title}</p>
                      <p className="text-[14px] leading-[25px] md:text-[15px] md:leading-[28px] tracking-[.03em]">{data.thirdSlide.des}</p>
                    </div>
                    <div className="flex gap-[30px] lg:gap-[70px] lg:mb-[16px] flex-col md:flex-row">
                      {data.thirdSlide.list.map((item, index) => (
                        <div className="w-full md:w-[calc(50%-15px)] lg:w-[calc(50%-35px)]" key={index}>
                          <p className="mb-[10px] md:mb-[15px] text-[20px] md:text-[25px] leading-[1.2] tracking-[.03em] font-medium font-heading">{item.listTitle}</p>
                            <div className="flex flex-col gap-[10px] md:gap-[15px] lg:gap-[20px]">
                              {item.listItems.map((listItem, index) => (
                                <p className="text-[14px] md:text-[15px] leading-[25px] tracking-[.03em]" key={index}>{listItem}</p>
                              ))}
                            </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <Link
                        title="UI UX Design Portfolio"
                        to={data.link ? data.link : ''}
                        className="animated-link small font-medium font-heading text-[18px] md:text-[20px] md:leading-[1.5] leading-[normal] tracking-[.05em] flex items-center gap-[28px]"
                      >
                        <p className="max-w-[262px] normal md:max-w-none w-auto font-heading">
                          View Project{" "}
                        </p>
                        <span>
                          <LinkArrowSvg size={16} />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div> :
                <div className="link-project-slide text mobile-popup h-full" onClick={() => {setIsPopupOpen(true)}} role="button">
                  <div className="p-[20px] px-[30px] h-full md:p-[60px] justify-center lg:p-[60px] bg-[#1F1F1F] flex-col flex gap-[30px] md:gap-[40px] lg:gap-[60px]">
                    <div
                        title="UI UX Design Portfolio"
                        className=" font-medium justify-between font-heading text-[20px] md:text-[30px] leading-[1.45] mr-[20px] tracking-[.05em] flex items-center gap-[28px]"
                      >
                        <p className="max-w-[262px] normal md:max-w-none w-auto font-heading">
                          Read More{" "}
                        </p>
                        <span className="rotate-45">
                          <LinkArrowSvg size={isMobile ? 16 : 24} />
                        </span>
                      </div>
                  </div>
                </div>
              }
            </SwiperSlide>
          }
        </Swiper>
      </div>

      {/* MODAL */}
      {data.thirdSlide !== undefined && isTablet &&
        <Modal
          isOpen={isPopupOpen}
          onRequestClose={() => {setIsPopupOpen(false)}}
          contentLabel="Custom Popup"
          className={'slide-modal'}
        >
          <button
            className="!absolute bg-[#E9EEED] hover:bg-[#DADFDE] w-[23px] h-[23px] md:w-[25px] md:h-[25px] lg:w-[30px] lg:h-[30px] rounded-[50%] flex items-center justify-center video-close text-[24px]"
            onClick={() => {setIsPopupOpen(false)}}
          >
            <StaticImage
              placeholder="none"
              src="../images/cross.webp"
              className="w-[9px]"
              alt="cross icon"
            />
          </button>
          <div className="link-project-slide text text-white">
            <div className="p-[20px] md:p-[40px] lg:p-[60px] bg-[#1F1F1F] flex-col flex gap-[30px] md:gap-[40px] lg:gap-[60px]">
              <div>
                <h3 className="mb-[16px] text-[22px] leading-[1.5] md:text-[28px] md:leading-[1.4] tracking-[.03em] font-semibold font-heading max-w-[90%]">{data.thirdSlide.title}</h3>
                <p className="text-[14px] leading-[25px] md:text-[15px] md:leading-[28px] tracking-[.03em]">{data.thirdSlide.des}</p>
              </div>
              <div className="flex gap-[30px] lg:gap-[70px] lg:mb-[16px] flex-col md:flex-row">
                {
                  data.thirdSlide.list.map((item, index) => (
                    <div className="w-full md:w-[calc(50%-15px)] lg:w-[calc(50%-35px)]" key={index}>
                      <h4 className="mb-[10px] md:mb-[15px] text-[20px] md:text-[25px] leading-[1.2] tracking-[.03em] font-medium font-heading">{item.listTitle}</h4>
                      <div className="flex flex-col gap-[10px] md:gap-[15px] lg:gap-[20px]">
                        {item?.listItems?.map((listItem, index) => (
                          <p className="text-[14px] md:text-[15px] leading-[25px] tracking-[.03em]" key={index}>{listItem}</p>
                        ))}
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <Link
                  title="UI UX Design Portfolio"
                  to={data.link ? data.link : ''}
                  className="animated-link small font-medium font-heading text-[18px] md:text-[20px] md:leading-[1.5] leading-[normal] tracking-[.05em] flex items-center gap-[28px]"
                >
                  <p className="max-w-[262px] normal md:max-w-none w-auto font-heading">
                    View Project{" "}
                  </p>
                  <span>
                    <LinkArrowSvg size={16} />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};
export default ProjectSection;
