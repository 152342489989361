import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import ProjectSection from "../components/ProjectSection";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import LinkArrowSvg from "../images/link-arrow";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import OurWorkslef from "../components/hire/OurWorkself";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire ReactJS Developers`,
    text: "Get Full-Scale Developers",
    content: "Hire Reactjs Developers from Octet to create interactive, dynamic, and feature-rich front-end applications.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire UI UX Designer',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire React.js Developers to Build Interactive Apps',
    text: "Hire ReactJS developers to enable the construction of online applications that are user-friendly, interactive, performant, and simple to launch.",
    list: [
      {
        title: 'Improved User Experience',
        text: `Our React Developers for Hire are focused on solving users' problems, developing fresh approaches, and creating stunning, user-friendly websites.`
      },
      {
        title: 'Smooth Integration',
        text: 'The performance of your project can be improved by seamlessly integrating third-party frameworks and APIs with our ReactJs Developers.'
      },
      {
        title: 'Enhanced Performance',
        text: `Hire dedicated React js developer to optimize your application's speed to increase engagement and produce a responsive and useful interface..`
      }
    ],
    image: ["hire-content-react.webp"],
    titleText: 'ReactJS Developers',
    alt: 'Benefits of hiring React developers'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top React js Developer for Hire',
    text: "Leading companies trust Octet Design Studio to hire ReactJS Developers to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire React Developers In 48 Hours',
        text: 'Our top ReactJs Developers for Hire ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our React Developer for Hire to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our ReactJS developers commit 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire dedicated React Developers and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-react.webp"],
    titleText: 'Hire ReactJS developers',
    alt: 'Hire ReactJS developers from a leading web development agency'
  }
  const commonContent3 = {
    title: `3 Steps to Hire React.js Developer`,
    text: "Experience the Octet advantage by hiring ReactJS developers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our ReactJS developers for hire who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled React developers for hire to select the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire React.js developers, onboard them within 48 hours so they can dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-react.webp"],
    titleText: 'Steps to Hire UI UX Designers',
    alt: 'Simple steps to hire ReactJS developer from Octet'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, you have the freedom to choose your designer with flexible recruitment options. Select the hiring model that best suits your needs.",
  //   list: [
  //     {
  //       title: 'Hire on Fixed Cost Basis',
  //       text: "Get your project completed within a predetermined budget, ensuring cost-effectiveness and transparency."
  //     },
  //     {
  //       title: 'Hire on Time & Material Basis',
  //       text: "Flexibility is key with this model, pay for the actual hours worked and materials used, ideal for dynamic projects."
  //     },
  //     {
  //       title: 'Hire as a Full-time',
  //       text: "Secure a dedicated designer for ongoing projects, providing consistent support and expertise as part of your team."
  //     },
  //   ],
  //   image: ["hire-content-4.webp"],
  //   titleText: 'Hire UI UX Designer Flexibly',
  //   alt: 'Flexible Hiring Models to Hire UI UX Designers from our design agency'
  // }
  const cta = {
    title: `Hire ReactJS Developers to Fuel your Next Idea`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce ReactJSDevelopers",
      content: "Our ReactJS developers use various tools and software to develop apps that meet users' needs and help convert visitors into customers. Hire best React js developers to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "React Developer for SaaS",
      content:
        "SaaS ReactJS developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire React.js developer who will be a creative problem solver and develop beautiful and functional apps and websites that enhance the user experience.",
    },
    {
      title: "Enterprise ReactJS Developer",
      content: "Hire React.js developers who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our React JS developers for hire will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire ReactJS Developers to Execute your Vision`,
    des: `Hire React Developers to efficiently and perfectly build frontend app solutions from ideation, implementation, and ultimate delivery of digital products.`,
    tabs: ["Web App Development ", "Mobile App Development", "Server-Side Rendering", "Static Site Generation"],
    slides: [
      {
        title: 'Web Application Development',
        desc1: "Hire dedicated React developers to create interactive and dynamic web applications using ReactJS.",
        desc2: 'Our React developer for hire leverages React capabilities to create engaging and responsive web applications that meet your requirements.',
        list: [
          'Experienced in Client-side Rendering',
          'Expertise in SPAs',
          'Delivering User-friendly Experience'
        ]
      },
      {
        title: 'Mobile Application Development',
        desc1: "Hire React.js developer to develop mobile applications using React Native, a framework that allows ReactJS to be used for native mobile apps.",
        desc2: 'Our ReactJS developers for hire utilize this approach to efficiently develop cross-platform mobile applications with familiar JavaScript libraries and tools.',
        list: [
          'Expertise in iOS and Android Apps',
          'Experienced in Native Components',
          'Delivering Maximum Accessibility'
        ]
      },
      {
        title: 'Server-Side Rendering',
        desc1: "Hire best React.js developer for rendering React components on the server side before sending the HTML to the client.",
        desc2: 'By pre-rendering React components on the server, our React.js developers for hire ensure that users receive a fully formed web page, enhancing performance and user experience.',
        list: [
          'Providing Faster Load Times',
          'Expertise in SEO-friendly Websites',
          'Delivering Improved Performance'
        ]
      },
      {
        title: 'Static Site Generation',
        desc1: "Hire dedicated React js Developer to generate static HTML files for web pages at build time, rather than relying on server-side rendering for each request.",
        desc2: "Our React developer for hire utilizes this approach, which is commonly used with frameworks such as Gatsby to enhance website performance and user experience.",
        list: [
          'Providing Faster Load Times',
          'Delivering Improved Security',
          'Ability to Enhance SEO'
        ]
      }
    ],
    altOne: 'Web Application Development',
    titleOne: 'Web Application Development',
    altTwo: 'Mobile Application Development',
    titleTwo: 'Mobile Application Development',
    altThree: 'Server-Side Rendering',
    titleThree: 'Server-Side Rendering',
    altFour: 'Static Site Generation',
    titleFour: 'Static Site Generation',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Hire React.js Developers who are passionate and committed to developing captivating apps, websites, and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'ReactJs Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire Dedicated React Developers from Octet?",
    "How can you Hire React developers from Octet?",
    "Which companies Hire React.js Developer?",
    "Does your React Developer for Hire come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of ReactJS developers who specialize in crafting engaging and functional interfaces.`,
        `Our ReactJS developers for hire have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `Hire dedicated React js developers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring ReactJS developers from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable React js developers to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring React developers from us, onboard them onto your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `React.js developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile app, building websites, software interfaces, or consumer electronics, our React Developers for hire can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring React Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Dedicated ReactJS Developers from Octet",
    para: 'Hire dedicated React Developers with the necessary abilities and experience to create real-time, scalable online and mobile applications using Flexible Engagement Models.',
    content: [
      {
        title: "ReactJS Consulting Services",
        text: "Octet's Reactjs Developers for Hire collaborate with you to make educated decisions, develop your projects, and implement the finest React practices and techniques.",
      },
      {
        title: "ReactJS UI UX Development",
        text: "Hire React.js Developer to construct engaging interfaces utilizing React UI UX development, focusing on user-centric experiences.",
      },
      {
        title: "ReactJS App Development",
        text: "Utilizing our team's extensive knowledge and state-of-the-art technology, our Reactjs Developers for Hire develop captivating and intuitive cross-platform applications that cater to users' diverse needs.",
      },
      {
        title: "ReactJS Web Development",
        text: "Our React Developer for Hire specializes in crafting high-performance websites meticulously designed to rank well on search engines and drive significant traffic to your online platform. ",
      },
      {
        title: "Custom ReactJS Development",
        text: "Utilize the expertise of our dedicated Reactjs Developers for Hire who leverage their precision, in-depth understanding, and tailored ReactJs Services to bring your distinctive concepts to fruition.",
      },
      {
        title: "Migration & Porting Services",
        text: "Hire React.js Developer to experience a seamless transition to React or a smooth migration of existing applications, all while preserving data and functionality.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire Dedicated React Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of ReactJS developers who specialize in crafting engaging and functional interfaces. 
          
          Our ReactJS developers for hire have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          Hire dedicated React js developers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire React developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring ReactJS developers from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable React js developers to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring React developers from us, onboard them onto your project team and provide them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire React.js Developer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "React.js developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile app, building websites, software interfaces, or consumer electronics, our React Developers for hire can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does your React Developer for Hire come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring React Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money."
              }
            }]
          }          
        `}
      </script>
    </Helmet>
      <Layout hire="Hire ReactJS Developers">
        <div>
          <HireBanner data={banner} pageName="Hire ReactJS Developers" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Get Industry's Best React Developer for Hire`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our ReactJS developers, hiring React developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire ReactJS Developers | React Developer for Hire"
    description="Hire ReactJS developers to build the highest quality websites and applications tailored to your business needs."
  />
);